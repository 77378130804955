@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.bg-dark {
  background-color: #8b0000 !important;
  color: #ffffff;
}
.footer {
  color: #550101;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
}
#contact-us {
  background-color: #9b6868;
  color: #ffffff;
  font-weight: bolder;
}
