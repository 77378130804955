.pv-recommendation-entity:first-child {
  padding-top: 0;
  border-top: none;
}
.pv-recommendation-entity {
  margin-right: 0;
  display: flex;
  border-top: 1px solid #e6e9ec;
  padding: 16px 0;
}

/* image settings */
.pv-recommendation-entity img {
  border-radius: 50%;
}

.pv-recommendation-entity__header {
  width: 33%;
}

.pv-recommendation-entity__highlights {
  width: 66%;
  padding-left: 16px;
}

.pv-profile-wrapper a:visited {
  color: #fff;
}
.pv-profile-wrapper a {
  text-decoration: none;
}

.pv-recommendation-entity__member {
  display: block;
  overflow: auto;
  text-decoration: none;
}

a.pv-recommendation-entity__member {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border-bottom: 0px #fff;
}

/* title of recommending */
.t-14 {
  font-size: 1rem;
  line-height: 1.42857;
}

/* date and relationship */
.t-12 {
  font-size: 1rem;
  line-height: 1.33333;
}

/* more text styles */
.t-black {
  color: rgba(0, 0, 0, 0.9);
}
.t-bold {
  font-weight: 600;
}
.t-normal {
  font-weight: 400;
}
.t-black--light {
  color: rgba(0, 0, 0, 0.6);
}
.section-info p {
  font-size: 1.15em;
}
.section-info .fab {
  background-color: #0e76a8;
  color: white;
  padding: 0.1em;
}
#home-banner {
  background-color: #020963;
  color: white;
}
.btn-dark {
  background-color: #8b0000;
}
iframe {
  display: inline-block;
  border-radius: 1rem;
}
iframe .ytp-large-play-button ytp-button {
  fill: #020963;
}

.showcase {
  position: relative;
  right: 0;
  width: 100%;
  min-height: 80vh;
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #111;
  transition: 0.5s;
  z-index: 2;
}
footer .nav-link {
  color: #111;
}
.showcase video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #03a9f4;
  mix-blend-mode: overlay;
}
.text {
  position: relative;
  /* z-index: 10; */
}

.text h2 {
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
  text-align: center;
}
.text h3 {
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
  text-align: center;
}
.text p {
  color: #fff;
  margin: 20px 0;
  font-weight: 400;

  /* max-width: 700px; */
}
.btn-dark:hover {
  color: wheat;
  background-color: #8d0e0e;
}

.social {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social li {
  list-style: none;
}
.social li a {
  display: inline-block;
  margin-right: 20px;
  filter: invert(1);
  transform: scale(0.5);
  transition: 0.5s;
}
.social li a:hover {
  transform: scale(0.5) translateY(-15px);
}
@media (max-width: 600px) {
  .text p {
    font-size: 1.1rem;
  }
}
@media (min-width: 601px) {
  .showcase,
  .showcase header {
    padding: 40px;
  }
  .text h2 {
    font-size: 3em;
  }
  .text h3 {
    font-size: 2em;
  }
}
