.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.95%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
* {
  font-size: large;
}

i {
  padding: 0;
}
i {
  padding: 0.1rem;
  border-radius: 0.05rem;
}
.btn-facebook {
  background-color: #4267b2;
  color: white;
}
.btn-vimeo {
  background-color: #86c9ef;
  color: white;
}
.btn-twitter {
  background-color: #1da1f2;
  color: white;
}
.btn-youtube {
  background-color: #ff0000;
  color: white;
}
.btn-linkedin {
  background-color: #0e76a8;
  color: white;
}
.btn-facebook:hover,
.btn-vimeo:hover,
.btn-twitter:hover,
.btn-youtube:hover,
.btn-linkedin:hover {
  color: wheat;
}
